
import LazyImg from "@/Components/LazyImg";
import Modal from "@/Components/Modal";
import { Button } from "@/Components/ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "@/Components/ui/card";
import { ScrollArea } from "@/Components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/Components/ui/tabs";
import { navigate } from "@/Hooks/Functions";
import { usePage } from "@inertiajs/react";
import { useGlobalModals } from "@state/store";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { create } from "zustand";
import { useShallow } from "zustand/react/shallow";
import DropzoneSingle from "./DropzoneSingle";
import Empty from "./Empty";
import { LaravelPagination } from "./LaravelPagination";


const fetchAiData = async (page) => {
    const { data } = await window.axios.get(route('prompt.mine-paginate', { per_page: 15, page: page }));
    return data;
}

const fetchUploadedData = async (page) => {
    const { data } = await window.axios.get(route('file.mine', { types: 'images', per_page: 15, page: page }));
    return data;
}

const useTabStore = create((set) => ({
    aiPage: 1,
    imgPage: 1,
    setAiPage: (val) => set({ aiPage: val }),
    setImgPage: (val) => set({ imgPage: val }),
}));

export default function FileGalleryPersonal() {
    const { auth } = usePage().props;
    const [tab, setTab] = useState('upload'); //or ai-resources,images
    const { aiPage, imgPage, setAiPage, setImgPage } = useTabStore(useShallow(state => ({
        aiPage: state.aiPage,
        imgPage: state.imgPage,
        setAiPage: state.setAiPage,
        setImgPage: state.setImgPage,
    })));

    const onCallback = useGlobalModals(state => state.myFileLibraryCallback);
    const { open, setOpen } = useGlobalModals(useShallow((state) => ({
        open: state.myFileLibrary,
        setOpen: state.setMyFileLibrary,
    })))

    const { data: aiData, isLoading: aiLoading } = useQuery({
        queryKey: ['my-ai-resources', aiPage],
        queryFn: () => fetchAiData(aiPage),
        keepPreviousData: true,
        enabled: tab === 'ai-resources' && !!auth?.user,
    })

    const { data: imgData, isLoading: imgLoading } = useQuery({
        queryKey: ['my-images', imgPage],
        queryFn: () => fetchUploadedData(imgPage),
        keepPreviousData: true,
        enabled: tab === 'images' && !!auth?.user,
    })

    const aiList = useMemo(() => aiData?.data || [], [aiData]);
    const aiLinks = useMemo(() => aiData?.links || [], [aiData]);

    const imgList = useMemo(() => imgData?.data || [], [imgData]);
    const imgLinks = useMemo(() => imgData?.links || [], [imgData]);
    const extraProps = useMemo(() => ({
        attachable_type: auth?.user ? 'App\\Models\\User' : null,
        attachable_id: auth?.user?.id,
    }), [auth?.user])

    if (!auth?.user)
        return <Modal title="File Gallery" className="max-w-7xl z-[55]" show={open} onClose={() => setOpen(false)}>
            <Empty text="Please login to continue" className="min-h-[calc(100vh-100px)]" >
                <Button onClick={() => {
                    setOpen(false);
                    navigate(route('login'));
                }} className="px-8">Proceed To Login</Button>
            </Empty>
        </Modal>

    return (
        <Modal title="File Gallery" className="max-w-7xl h-[95vh]" show={open} onClose={() => setOpen(false)}>
            <Tabs value={tab} onValueChange={(v) => setTab(v)} className="w-full">
                <Card className="flex flex-col w-full shadow-none border-none text-white">
                    <CardHeader className="flex flex-col w-full justify-start items-center px-4">
                        <TabsList className="grid grid-cols-3 rounded-full w-full">
                            <TabsTrigger className="rounded-full px-4" value="upload">Upload Image</TabsTrigger>
                            <TabsTrigger className="rounded-full px-4" value="ai-resources">AI Prompt Images</TabsTrigger>
                            <TabsTrigger className="rounded-full px-4" value="images">Image Library</TabsTrigger>
                        </TabsList>
                    </CardHeader>
                    <ScrollArea className="h-[calc(100vh-240px)]">
                        <CardContent className="w-full flex flex-col gap-2 text-foreground">
                            <TabsContent value="upload">
                                <div className="mx-auto md:2/3 w-full flex flex-col justify-center items-center p-4">
                                    <DropzoneSingle extraProps={extraProps}
                                        className="border-2 rounded-2xl border-primary text-primary min-h-[calc(100vh-300px)]"
                                        onChange={(val) => onCallback({ type: 'upload', item: val })}
                                    />
                                </div>
                            </TabsContent>
                            <TabsContent value="ai-resources">
                                <ResponsiveMasonry
                                    className="w-full p-4"
                                    columnsCountBreakPoints={{
                                        350: 2,
                                        800: 4,
                                        1024: 6,
                                    }}
                                >
                                    <Masonry gutter="1em">
                                        {aiList.map((item) => (
                                            item.images.map(image => <div className="flex flex-col group relative justify-center items-center" key={item.id}>
                                                <div className="bg-transparency w-fit h-fit rounded-2xl shadow-xl">
                                                    <div className="bg-primary/50 absolute-center w-full h-full group-hover:visible rounded-2xl invisible"></div>
                                                    <LazyImg
                                                        height={50}
                                                        src={image.url_inline}
                                                        className="aspect-auto cursor-pointer rounded-2xl hover:scale-110 object-fill"
                                                    />
                                                </div>
                                                <div className="absolute group-hover:flex left-0 top-0 w-full h-full hidden flex-col justify-center items-center">
                                                    <Button size="sm" variant="theme" onClick={() => onCallback({ type: 'prompt', item: image })} >Select</Button>
                                                </div>
                                            </div>)
                                        ))}
                                    </Masonry>
                                </ResponsiveMasonry>
                            </TabsContent>
                            <TabsContent value="images">
                                <ResponsiveMasonry
                                    className="w-full p-4"
                                    columnsCountBreakPoints={{
                                        350: 2,
                                        800: 4,
                                        1024: 6,
                                    }}
                                >
                                    <Masonry gutter="1em">
                                        {imgList.map((item) => (
                                            <div className="flex flex-col group relative justify-center items-center" key={item.id}>
                                                <div className="bg-transparency w-fit relative h-fit rounded-2xl shadow-xl shadow-spread-2">
                                                    <div className="bg-primary/50 absolute-center w-full h-full group-hover:visible rounded-2xl invisible"></div>
                                                    <LazyImg
                                                        height={50}
                                                        src={item.url_thumb}
                                                        className="aspect-auto cursor-pointer rounded-2xl hover:scale-110 object-fill"
                                                    />
                                                </div>
                                                <div className="absolute group-hover:flex left-0 top-0 w-full h-full hidden flex-col justify-center items-center">
                                                    <Button size="sm" variant="theme" className="px-6" onClick={() => onCallback({ type: 'image', item: item })} >Select</Button>
                                                </div>
                                            </div>
                                        ))}
                                    </Masonry>
                                </ResponsiveMasonry>
                            </TabsContent>
                        </CardContent>
                    </ScrollArea>
                    {tab !== 'upload' && <CardFooter className="flex flex-row justify-end w-full py-3 items-center">
                        {tab === 'images' && <LaravelPagination className="text-primary" dense returnUrl={false} links={imgLinks} navigate={setImgPage} />}
                        {tab === 'ai-resources' && <LaravelPagination className="text-primary" dense returnUrl={false} links={aiLinks} navigate={setAiPage} />}
                    </CardFooter>}
                </Card>
            </Tabs>
        </Modal>
    );
}
